<template>
  <div>
    <div
      v-if="step===1"
      class="columns is-multiline"
    >
      <div class="column is-12">
        <h3 class="has-text-secondary has-text-weight-bold is-size-4">
          <i class="el-icon-warning has-text-danger" /> {{ $t('title') }}
        </h3>
      </div>
      <div class="column is-12">
        <p v-html="$t('desc',{name: value.reservationNumber})" />
      </div>
      <div class="column has-text-right is-12">
        <a-button
          class="button-secondary-outline m-r-2x"
          style="min-width: 125px;"
          :disabled="loading"
          @click="handleCancel"
        >
          {{ $t('Cancel') }}
        </a-button>
        <a-button
          class="button-secondary"
          style="min-width: 125px;"
          :loading="loading"
          @click="handleDel"
        >
          {{ $t('delete') }}
        </a-button>
      </div>
    </div>
    <div
      v-if="step===2"
      class="columns is-multiline"
    >
      <div class="column is-12">
        <h3 class="has-text-secondary has-text-weight-bold is-size-4">
          {{ $t('deleted') }}
        </h3>
      </div>
      <div class="column is-12">
        <p v-html="$t('deleted-desc',{name: value.reservationNumber})" />
      </div>
      <div class="column has-text-right is-12">
        <a-button
          class="button-secondary-outline"
          style="min-width: 125px;"
          @click="handleDeleteSuccess"
        >
          {{ $t('Ok') }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
  "title": "Delete ND401000?",
  "desc": "Are you use you want to delete ND401000 <strong>{name}</strong> ?",
  "Cancel": "Cancel",
  "delete": "Yes, Delete",
  "deleted": "ND401000 deleted",
  "deleted-desc": "ND401000 <strong>{name}</strong> has been removed.",
  "Ok": "Ok"
},
"ja": {
  "title": "Delete ND401000?",
  "desc": "Are you use you want to delete ND401000 <strong>{name}</strong> ?",
  "Cancel": "Cancel",
  "delete": "Yes, Delete",
  "deleted": "ND401000 deleted",
  "deleted-desc": "ND401000 <strong>{name}</strong> has been removed.",
  "Ok": "Ok"
}
}
</i18n>

<script>
export default {
  name: 'DelReservationMeal',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['cancel', 'close'],
  data() {
    return {
      step: 1,
      loading: false,
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleDeleteSuccess() {
      this.$emit('close');
    },
    handleDel() {
      this.loading = true;
      this.$store
        .dispatch('simulations/deleteReservationMeal', this.value.reservationNumber)
        .then(() => {
          this.loading = false;
          this.step = 2;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
