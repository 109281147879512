<template>
  <div
    class="columns form is-mini-gap is-multiline"
  >
    <div class="column is-narrow">
      <a-input
        v-model:value="reservationNumber"
        :size="size"
        :placeholder="$t('Reservation Number')"
        @change="handleFilter"
      />
    </div>
    <div class="column is-narrow">
      <a-input
        v-model:value="mealFlag"
        :size="size"
        :placeholder="$t('Meal Flag')"
        @change="handleFilter"
      />
    </div>
    <div class="column">
      <a-button
        v-show="reservationNumber || mealFlag"
        :size="size"
        style="min-width: 110px;"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { cleanObject } from '@/utils/util';

export default {
  name: 'RoomSizeFilter',
  emits: ['filter'],
  data() {
    return {
      size: 'large',
      reservationNumber: undefined,
      mealFlag: undefined,
    };
  },
  mounted() {
    this.reservationNumber = this.$route.query.reservationNumber || undefined;
    this.mealFlag = this.$route.query.meal || undefined;
  },
  methods: {
    handleFilter: debounce(async function handleFilter() {
      const query = cleanObject({
        ...this.$route.query,
        reservationNumber: this.reservationNumber,
        meal: this.mealFlag,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 700),
    handleClear() {
      this.reservationNumber = undefined;
      this.mealFlag = undefined;
      this.handleFilter();
    },
  },
};
</script>
