<template>
  <a-form
    ref="forms"
    :model="form"
    :rules="rules"
    class="columns is-mini-gap is-multiline"
  >
    <div class="column is-12">
      <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
        {{ title }}
      </h2>
    </div>
    <a-form-item
      class="column is-12"
      :label="$t('Hotel ID')"
      name="hotelId"
    >
      <a-input
        ref="hotelId"
        v-model:value="form.hotelId"
        :size="size"
        type="text"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Room ID')"
      name="roomId"
    >
      <a-input
        v-model:value="form.roomId"
        :size="size"
        type="text"
      />
    </a-form-item>
    <div
      class="columns is-12"
      style="padding: 5px; width: 102%;"
    >
      <a-form-item
        class="column is-6"
        :label="$t('Unit')"
        name="unit"
      >
        <div class="is-flex">
          <a-input
            v-model:value="form.unit"
            style="width: 100%;"
            :size="size"
          />
        </div>
      </a-form-item>
      <a-form-item
        class="column is-6"
        :label="$t('Value')"
        name="value"
      >
        <div class="is-flex">
          <a-input-number
            v-model:value="form.value"
            min="0"
            style="width: 100%;"
            :size="size"
          />
        </div>
      </a-form-item>
    </div>
    <div
      class="column has-text-right is-12 m-t-4x"
    >
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px;"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 115px;"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  name: 'RoomAddonForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['cancel', 'close'],
  data() {
    return {
      size: 'large',
      isSubmitting: false,
      form: {
        hotelId: undefined,
        roomId: undefined,
        unit: undefined,
        value: undefined,
      },
      rules: {
        hotelId: [
          {
            required: true,
            message: this.$t('Hotel ID is required'),
            type: 'number',
            transform: (value) => Number(value),
            trigger: ['change', 'blur'],
          },
        ],
        roomId: [
          {
            required: true,
            message: this.$t('Room ID is required'),
            trigger: ['change', 'blur'],
          },
        ],
        unit: [
          {
            required: true,
            message: this.$t('Unit is required'),
            trigger: ['change', 'blur'],
          },
        ],
        value: [
          {
            required: true,
            type: 'number',
            message: this.$t('Value is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.value && this.value.id
        ? this.$t('Edit room size')
        : this.$t('Add room size');
    },
  },
  watch: {
    value: {
      handler(nv) {
        if (nv) {
          this.form.hotelId = nv.hotelId || undefined;
          this.form.roomId = nv.roomId || undefined;
          this.form.unit = nv.unit || undefined;
          this.form.value = nv.value || undefined;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.hotelId.focus();
    });
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.forms.validate();
        this.isSubmitting = true;
        if (this.value && this.value.id) {
          const payload = { id: this.value.id, ...this.form };
          await this.$store.dispatch('simulations/updateRoomSize', payload);
        } else {
          const payload = { ...this.form };
          await this.$store.dispatch('simulations/addRoomSize', payload);
        }

        this.isSubmitting = false;
        this.$emit('close');
        this.resetFields();
      } catch (error) {
        this.isSubmitting = false;
      }
    },
    resetFields() {
      this.$refs.forms.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
