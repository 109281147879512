<template>
  <div
    class="columns form is-mini-gap is-multiline"
  >
    <div class="column is-narrow">
      <a-input
        v-model:value="hotelId"
        :size="size"
        :placeholder="$t('Hotel ID')"
        @change="handleFilter"
      />
    </div>
    <div class="column is-narrow">
      <a-input
        v-model:value="roomId"
        :size="size"
        :placeholder="$t('Room ID')"
        @change="handleFilter"
      />
    </div>
    <div class="column">
      <a-button
        v-show="hotelId||roomId"
        :size="size"
        style="min-width: 110px;"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { cleanObject } from '@/utils/util';

export default {
  name: 'RoomSizeFilter',
  emits: ['filter'],
  data() {
    return {
      size: 'large',
      hotelId: undefined,
      roomId: undefined,
    };
  },
  mounted() {
    this.hotelId = this.$route.query.hotelId || undefined;
    this.roomId = this.$route.query.roomId || undefined;
  },
  methods: {
    handleFilter: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        hotelId: this.hotelId,
        roomId: this.roomId,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 700),
    handleClear() {
      this.hotelId = undefined;
      this.roomId = undefined;
      this.handleFilter();
    },
  },
};
</script>
