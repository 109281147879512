<template>
  <a-form
    ref="forms"
    :model="form"
    :rules="rules"
    class="columns is-mini-gap is-multiline"
  >
    <div class="column is-12">
      <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
        {{ title }}
      </h2>
    </div>
    <a-form-item
      class="column is-12"
      :label="$t('Reservation number')"
      name="reservationNumber"
    >
      <a-input
        ref="reservationNumber"
        v-model:value="form.reservationNumber"
        :size="size"
        type="text"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Meal flag')"
      name="mealFlag"
    >
      <a-input
        v-model:value="form.mealFlag"
        :size="size"
        type="text"
      />
    </a-form-item>
    <div
      class="columns is-12"
      style="padding: 5px; width: 102%;"
    >
      <a-form-item
        class="column is-6"
        :label="$t('Breakfast flag')"
        name="breakfastFlag"
      >
        <div class="is-flex">
          <a-input
            v-model:value="form.breakfastFlag"
            style="width: 100%;"
            :size="size"
          />
        </div>
      </a-form-item>
      <a-form-item
        class="column is-6"
        :label="$t('Dinner flag')"
        name="dinnerFlag"
      >
        <div class="is-flex">
          <a-input
            v-model:value="form.dinnerFlag"
            min="0"
            style="width: 100%;"
            :size="size"
          />
        </div>
      </a-form-item>
    </div>
    <div
      class="column has-text-right is-12 m-t-4x"
    >
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px;"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 115px;"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  name: 'ReservationMealForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['cancel', 'close'],
  data() {
    return {
      size: 'large',
      isSubmitting: false,
      form: {
        reservationNumber: undefined,
        mealFlag: undefined,
        breakfastFlag: undefined,
        dinnerFlag: undefined,
      },
      rules: {
        reservationNumber: [
          {
            required: true,
            message: this.$t('Reservation flag is required'),
            trigger: ['change', 'blur'],
          },
        ],
        mealFlag: [
          {
            required: true,
            message: this.$t('Meal flag is required'),
            trigger: ['change', 'blur'],
          },
        ],
        breakfastFlag: [
          {
            required: true,
            message: this.$t('Breakfast flag is required'),
            trigger: ['change', 'blur'],
          },
        ],
        dinnerFlag: [
          {
            required: true,
            message: this.$t('Dinner flag is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.value && this.value.reservationNumber
        ? this.$t('Edit ND401000')
        : this.$t('Add ND401000');
    },
  },
  watch: {
    value: {
      handler(nv) {
        if (nv) {
          this.form.reservationNumber = nv.reservationNumber || undefined;
          this.form.mealFlag = nv.mealFlag || undefined;
          this.form.breakfastFlag = nv.breakfastFlag || undefined;
          this.form.dinnerFlag = nv.dinnerFlag || undefined;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.reservationNumber.focus();
    });
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.forms.validate();

        this.isSubmitting = true;

        await this.$store.dispatch('simulations/saveReservationMeal', this.form);

        this.$emit('close');
        this.resetFields();
      } finally {
        this.isSubmitting = false;
      }
    },
    resetFields() {
      this.$refs.forms.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
