<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('ND401000 List') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <Filter
              @filter="fetchData"
            />
          </div>
          <div
            class="column is-narrow"
          >
            <Modal ref="modalReservationMeal">
              <template #default="{hide}">
                <Form
                  :value="selected"
                  @close="() => {
                    hide();
                    fetchData();
                  }"
                  @cancel="() => {
                    hide();
                  }"
                />
              </template>
              <template #handler="{show}">
                <a
                  class="ant-btn ant-btn-lg ant-btn-primary"
                  @click.prevent="() => {
                    show();
                    selected = undefined;
                  }"
                >
                  {{ $t('Add ND401000') }}
                </a>
              </template>
            </Modal>
          </div>
        </div>
        <a-table
          row-key="id"
          row-class-name="pointer"
          :custom-row="selectRow"
          :columns="columns"
          :data-source="lists"
          :loading="isFetching"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #createdAt="{ text }">
            {{ $filters.date(text, { format: $variables.genericDateTimeFormat }) }}
          </template>
          <template #updatedAt="{ text }">
            {{ $filters.date(text, { format: $variables.genericDateTimeFormat }) }}
          </template>
          <template #action="{ record }">
            <Modal>
              <template #default="{hide}">
                <Delete
                  :value="record"
                  @close="()=>{hide();fetchData();}"
                  @cancel="hide"
                />
              </template>
              <template #handler="{show}">
                <DeleteFilled
                  class="has-text-danger"
                  @click.stop.prevent="show"
                />
              </template>
            </Modal>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { DeleteFilled } from '@ant-design/icons-vue';
import Modal from '@/components/Modal';
import Delete from '@/views/simulations/components/DelReservationMeal';
import Form from '@/views/simulations/components/ReservationMealForm';
import Filter from '@/views/simulations/components/ReservationMealFilter';
import listTable from '@/components/mixins/list-table';

export default {
  name: 'ReservationMeal',
  components: {
    Modal,
    Delete,
    DeleteFilled,
    Form,
    Filter,
  },
  mixins: [listTable],
  data() {
    return {
      lists: undefined,
      selected: undefined,
      isFetching: false,
      columns: [
        {
          title: 'Reservation number',
          dataIndex: 'reservationNumber',
          key: 'reservationNumber',
        },
        {
          title: 'Meal flag',
          dataIndex: 'mealFlag',
          key: 'mealFlag',
        },
        {
          title: 'Breakfast flag',
          dataIndex: 'breakfastFlag',
          key: 'breakfastFlag',
        },
        {
          title: 'Dinner flag',
          dataIndex: 'dinnerFlag',
          key: 'dinnerFlag',
        },
        {
          title: 'Generated at',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: true,
          slots: { customRender: 'createdAt' },
        },
        {
          title: 'Updated at',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          sorter: true,
          defaultSortOrder: 'descend',
          slots: { customRender: 'updatedAt' },
        },
        {
          title: ' ',
          key: 'action',
          align: 'right',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    this.handleTableChange({}, {}, {
      field: 'updatedAt',
      order: 'descend',
    });
  },
  methods: {
    selectRow(record) {
      return {
        onClick: () => {
          this.selected = record;
          this.$refs.modalReservationMeal.show();
        },
      };
    },
    async handleTableChange(pagination, filters, sorter) {
      await this.prepareTable(pagination, filters, sorter);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      return this.$store
        .dispatch('simulations/getReservationMeal', this.$route.query)
        .then((resp) => {
          const { data, pagination } = resp;

          this.lists = data;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
