export default {
  data() {
    return {
      pagination: {},
    };
  },
  methods: {
    prepareTable(pagination, filters, sorter) {
      const query = { ...this.$route.query };

      if (Object.keys(pagination).length > 0) {
        query.page = pagination.current;
        query.pageSize = pagination.pageSize;

        this.pagination = {
          ...this.pagination,
          current: query.page,
          pageSize: query.pageSize,
        };
      }

      if (Object.keys(sorter).length > 0) {
        const { field, order } = sorter;
        const orderKeys = { ascend: 'asc', descend: 'desc' };

        query.sortField = field;
        query.sortOrder = orderKeys[order] || 'desc';
      }

      return this.$router
        .push({ query })
        .catch(() => {});
    },
    setPagination(pagination) {
      if (pagination) {
        this.pagination = {
          current: pagination.current,
          pageSize: pagination.size,
          total: pagination.total,
        };
      }
    },
  },
};
